<template>
  <div>
    <div class="container mb-5">
      <div class="sub-container pt-2">
        <div class="text-center d-sm-none d-block mb-4">
          <img
            src="/img/logo_selcare_512.png"
            class="home-logo"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="main-title">
          <h2>Hello, how can we help you today?</h2>
        </div>
        <div class="mx-0 row justify-content-center">
          <div
            class="col-md-3 col-sm-12 col-6 mt-sm-2 mt-2-custom pr-sm-3 pr-2"
          >
            <router-link to="/e-prescription/checking">
              <div id="card-3" class="card">
                <img src="/img/card_3.png" style="opacity: 0.6" alt="" />
                <!-- <span class="name">Doctor's <br /> Visit</span> -->
                <span class="name">Telemedicine/E-prescription</span>
                <!-- <button class="btn coming-soon" disabled>coming soon</button> -->
              </div>
            </router-link>
          </div>
          <div
            class="col-md-3 col-sm-12 col-6 mt-sm-2 mt-2-custom pl-sm-3 pl-2"
          >
            <router-link to="/e-prescription/appointment">
              <div id="card-4" class="card">
                <img src="/img/card_4.png" style="opacity: 0.6" alt="" />
                <!-- <span class="name">Physiotherapy <br /> & Caregivers</span> -->
                <span class="name">GP/Dental Appointment</span>
                <!-- <button class="btn coming-soon" disabled>coming soon</button> -->
              </div>
            </router-link>
          </div>
        </div>
        <div id="home-description" class="mx-0 row mt-5">
          <div class="col-12">
            <p class="description-text text-muted">
              Selgate Healthcare is a member of Selgate Group of Companies which
              focuses on providing the best healthcare solution for the people.
              We are confident of serving the clients better through a network
              of general practice clinics, dental clinics, pharmacy and home
              nursing setup manned by well- trained doctors, dentists, nurses
              and pharmacists. Call us at
              <a class=" font-weight-bold" style="color: #a91a18;" href="tel:1800226600"
                >1 800 22 6600</a
              >
              or email us at
              <a
                class=" font-weight-bold"
                style="color: #a91a18;"
                href="mailto:info@selgatehealthcare.com"
                >info@selgatehealthcare.com</a
              >. We are always there to care.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePharmacist",
};
</script>


<style scoped>
.container {
  position: relative;
  height: calc(100vh - 165px);
}
.sub-container {
  padding: 0px 150px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}
.main-title {
  text-align: center;
  margin-bottom: 30px;
}

.card {
  height: 160px;
  border-radius: 15px;
  border: unset;
  position: relative;
  overflow: hidden;
}
.card span.name {
  color: #ffffff;
  font-weight: 500;
  position: absolute;
  bottom: 15px;
  left: 12px;
}
.card img {
  position: absolute;
  right: 0px;
  height: 100%;
}
.card.no-exist span.name,
.card.no-exist img {
  opacity: 0.4;
}
.card.no-exist button.coming-soon {
  background-color: rgba(144, 152, 161, 0.7);
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  width: 100px;
  border-radius: 20px;
  position: absolute;
  top: 65px;
  left: 45px;
  opacity: 1 !important;
}

#card-1 {
  background-color: #e14db8;
}
#card-2 {
  background-color: #5e38ba;
}
#card-3 {
  background: linear-gradient(138.52deg, #e85d27 4.81%, #ff2e31 100.76%);
}
#card-4 {
  background: linear-gradient(
    138.52deg,
    rgba(39, 104, 232, 0.67) 4.81%,
    rgba(46, 192, 255, 0.67) 100.76%
  );
}

@media (max-width: 1199px) {
  .sub-container {
    padding: 0px 80px;
  }
}
@media (max-width: 991px) {
  .sub-container {
    padding: 0px 0px;
  }
  .card img {
    position: absolute;
    right: 0px;
    height: 80%;
    bottom: 0px;
  }
}
@media (max-width: 575px) {
  .sub-container {
    padding: 0px 10px;
    /* margin-top: 30px; */
  }
  .container {
    height: calc(100vh - 140px);
  }
  .home-logo {
    height: 100px;
  }
  .main-title h2 {
    font-size: 1.2rem;
  }
  .card img {
    position: absolute;
    right: 0px;
    height: 80%;
    bottom: 0px;
  }
  p.description-text {
    font-size: 11px;
    text-align: justify;
    margin-top: -15px;
    color: #545d69;
  }
  p.description-text .text-muted {
    color: #2b2b2b !important;
  }
}
@media (max-height: 700px) {
  .container {
    height: 100vh;
  }
}

/** Others */
.mt-2-custom {
  margin-top: 15px;
}
</style>